<template>
    <div class="block-section">
        <Toast />
        <div class="block-content">
            <div class="surface-ground min-h-screen px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
                <div class="surface-card py-3 p-4 shadow-2 border-round w-full md:w-6 lg:w-4">
                    <div class="text-center mb-4">
                        <img src="../assets/logo.png" alt="Image" height="50" class="mb-3">
                        <div class="text-900 text-3xl font-medium mb-3">Welcome to Nishcinto!</div>
                        <span class="text-600 font-medium line-height-3" v-if="resetCodeRes">Enter Your New Password</span>
                        <span class="text-600 font-medium line-height-3" v-else>Enter Your Reset Code</span>
                    </div>

                    <form @submit.prevent="resetPassword(!validReset$.$invalid)" class="p-fluid" v-if="resetCodeRes">
                        <div class="field">
                            <span class="p-fluid">
                                <div class="my-3">
                                    <label for="password" class="block text-900 font-medium mb-2">Password</label>
                                    <InputText
                                        :class="{'p-invalid': validReset$.password.$invalid && submittedRP}"
                                        id="password"
                                        type="text"
                                        v-model="validReset$.password.$model"
                                    />
                                    <span v-if="validReset$.password.$error && submittedRP">
                                        <span
                                            id="password-error"
                                            v-for="(error, index) of validReset$.password.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Password")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(validReset$.password.$invalid && submittedRP) || validReset$.password.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ validReset$.password.required.$message.replace("Value", "Password") }}
                                    </small>
                                </div>
                            </span>
                        </div>
                        <Button label="Submit" icon="pi pi-check" class="w-full my-4" :loading="loading" type="submit"></Button>
                    </form>

                    <form @submit.prevent="resetCode(!valid$.$invalid)" class="p-fluid" v-else>
                        <div class="field">
                            <span class="p-fluid">
                                <div class="my-3">
                                    <label for="reset_code" class="block text-900 font-medium mb-2">Reset Code</label>
                                    <InputText
                                        :class="{'p-invalid': valid$.reset_code.$invalid && submitted}"
                                        id="reset_code"
                                        type="text"
                                        v-model="valid$.reset_code.$model"
                                    />
                                    <span v-if="valid$.reset_code.$error && submitted">
                                        <span
                                            id="reset_code-error"
                                            v-for="(error, index) of valid$.reset_code.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Reset Code")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.reset_code.$invalid && submitted) || valid$.reset_code.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.reset_code.required.$message.replace("Value", "Reset Code") }}
                                    </small>
                                </div>
                            </span>
                        </div>
                        <Button label="Submit" icon="pi pi-check" class="w-full my-4" :loading="loading" type="submit"></Button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Axios from 'axios';
import { ref, reactive } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import useVuelidate from '@vuelidate/core';
import { required, maxLength, minLength } from '@vuelidate/validators';

export default {
    setup() {
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();

        const state = reactive({
            email: route.query.email,
            reset_code : "",
        });

        const rules = {
            email: { required },
            reset_code : { required, maxLength: maxLength(255) },
        };

        const valid$ = useVuelidate (rules, state);

        const stateReset = reactive({
            password : "",
        });

        const rulesReset = {
            password : { required , minLength : minLength(6)},
        };

        const validReset$ = useVuelidate (rulesReset, stateReset);

        const resetCodeRes = ref();
        const submitted = ref(false);
        const submittedRP = ref(false);
        const loading = ref(false);
        const massage = ref(null);

        const resetCode = (isFormValid) => {
            loading.value = true;
            submitted.value = true;
            if (!isFormValid) {
                loading.value = false;
                return;
            }
            Axios
                .post("api/auth/operator/verify-reset-code", {
                    email: state.email,
                    reset_code: state.reset_code,
                })
                .then((res) => {
                    loading.value = false;
                    if (res.data.response == "success") {
                        showToast("success", "Success", res.data.message);
                        resetCodeRes.value = res.data.data;
                    } else {
                        massage.value = res.data.message;
                        toast.add({severity:'error', summary: 'Error Message', detail: res.data.message, life: 5000});
                    }
                })
                .catch((err) => {
                    loading.value = false;
                    console.log(err);
                    toast.add({severity:'error', summary: 'Error Message', detail: err, life: 5000});
                });
        };

        const resetPassword = (isFormValid) => {
            submittedRP.value = true;
            if (!isFormValid) {
                loading.value = false;
                return;
            }
            loading.value = true;
            Axios
                .post("api/auth/operator/reset-password", {
                    forgot_password_id: resetCodeRes.value.id,
                    password: stateReset.password,
                })
                .then((res) => {
                    loading.value = false;
                    if (res.data.response == "success") {
                        showToast("success", "Success", res.data.message);
                        router.replace('/signin');
                    } else {
                        massage.value = res.data.message;
                        toast.add({severity:'error', summary: 'Error Message', detail: res.data.message, life: 5000});
                    }
                })
                .catch((err) => {
                    loading.value = false;
                    console.log(err);
                    toast.add({severity:'error', summary: 'Error Message', detail: err, life: 5000});
                });
        };

        const showToast = (severity, summary, detail) => {
			toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
		};

        return {
            valid$,
            validReset$,
            submitted,
            submittedRP,
            loading,
            resetCode,
            massage,
            router,
            resetCodeRes,
            resetPassword,
        }
    },


}
</script>

<style scoped>

</style>